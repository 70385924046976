@import url("https://fonts.googleapis.com/css?family=Ubuntu&display=swap");
@import "../colors";
@import "./breakpoints";

$font-family: "Ubuntu", sans-serif;
$font-weight: bold;

$first-name-font-size: 90px;
$last-name-font-size: 60px;
$first-name-font-color: $global--color-text;
$last-name-font-color-normal: $global--color-highlight-neutral-darker;
$last-name-font-color-highlight: $global--color-highlight-bright;
$slide-start: -100%;
$slide-end: 0%;

$transition-duration: 400ms;
$transition-delay: 100ms;

$first-name-font-size-bp-one: 65px;
$last-name-font-size-bp-one: 40px;
$first-name-font-size-bp-two: 50px;
$last-name-font-size-bp-two: 30px;
$first-name-font-size-bp-three: 35px;
$last-name-font-size-bp-three: 20px;

.name {
  font-family: $font-family;
  font-weight: $font-weight;
  overflow-x: hidden;

  .sliding-names {
    &-appear {
      margin-left: $slide-start;
    }

    &-appear-done {
      margin-left: $slide-end;
      transition-delay: $transition-delay;
      transition-property: margin-left;
      transition-duration: $transition-duration;
    }
  }

  .first-name {
    font-size: $first-name-font-size;
    color: $first-name-font-color;
  }

  .last-name {
    font-size: $last-name-font-size;
    color: $last-name-font-color-normal;
  }
}

@media only screen and (max-width: $intro-breakpoint-one) {
  .name {
    .first-name {
      font-size: $first-name-font-size-bp-one;
    }

    .last-name {
      font-size: $last-name-font-size-bp-one;
    }
  }
}

@media only screen and (max-width: $intro-breakpoint-two) {
  .name {
    .first-name {
      font-size: $first-name-font-size-bp-two;
    }

    .last-name {
      font-size: $last-name-font-size-bp-two;
    }
  }
}

@media only screen and (max-width: $intro-breakpoint-three) {
  .name {
    .first-name {
      font-size: $first-name-font-size-bp-three;
    }

    .last-name {
      font-size: $last-name-font-size-bp-three;
    }
  }
}
