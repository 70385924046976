@import "./breakpoints";
@import "../colors";

.connect {
  display: flex;
  flex-direction: row;

  a {
    margin-right: 30px;
    width: 55px;
    height: 55px;

    svg {
      width: 55px;
      height: 55px;
      fill: $global--color-text;
    }

    @media only screen and (max-width: $intro-breakpoint-one) {
      width: 50px;
      height: 50px;
      svg {
        width: 50px;
        height: 50px;
      }
    }
    @media only screen and (max-width: $intro-breakpoint-two) {
      width: 45px;
      height: 45px;
      svg {
        width: 45px;
        height: 45px;
      }
    }
    @media only screen and (max-width: $intro-breakpoint-three) {
      width: 40px;
      height: 40px;
      svg {
        width: 40px;
        height: 40px;
      }
    }
  }

  a:last-child {
    margin-right: 0;
  }

  &__icons {
    &-appear {
      opacity: 0;
    }

    &-appear-done {
      opacity: 1;
      transition: opacity 0.4s ease-in-out;
      -moz-transition: opacity 0.4s ease-in-out;
      -webkit-transition: opacity 0.4s ease-in-out;
      -o-transition: opacity 0.4s ease-in-out;
    }
  }
}
