@import "../colors";
@import "./breakpoints";

$intro-height: 100%;
$particles-bottom-displacement: 100% - $intro-height;
$particles-background-color: $global--color-background;

$vertical-divider-height-start: 0px;
$vertical-divider-height-end: 200px;
$vertical-divider-width: 2px;
$vertical-divider-margin-left: 20px;
$vertical-divider-margin-right: 20px;
$vertical-divider-color: $global--color-text;
$vertical-divider-delay: 300ms;
$vertical-divider-duration: 300ms;

$vertical-divider-height-end-bp-one: 125px;
$vertical-divider-height-end-bp-two: 105px;
$vertical-divider-height-end-bp-three: 80px;

.intro {
  height: $intro-height;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__particles {
    position: absolute;
    z-index: -1;
    background-color: $particles-background-color;
    left: 0;
    right: 0;
    bottom: $particles-bottom-displacement;
    top: 0;
  }

  &__photo-name-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 30px;

    &__vertical-divider {
      &-appear {
        height: $vertical-divider-height-start;
        width: $vertical-divider-width;
        margin-left: $vertical-divider-margin-left;
        margin-right: $vertical-divider-margin-right;
        background: $vertical-divider-color;
      }

      &-appear-done {
        height: $vertical-divider-height-end;
        width: $vertical-divider-width;
        margin-left: $vertical-divider-margin-left;
        margin-right: $vertical-divider-margin-right;
        background: $vertical-divider-color;

        transition-property: height;
        transition-delay: $vertical-divider-delay;
        transition-duration: $vertical-divider-duration;
      }
    }
  }
}

@media only screen and (max-width: $intro-breakpoint-one) {
  .intro {
    &__photo-name-section {
      &__vertical-divider {
        &-appear-done {
          height: $vertical-divider-height-end-bp-one;
        }
      }
    }
  }
}

@media only screen and (max-width: $intro-breakpoint-two) {
  .intro {
    &__photo-name-section {
      &__vertical-divider {
        &-appear-done {
          height: $vertical-divider-height-end-bp-two;
        }
      }
    }
  }
}

@media only screen and (max-width: $intro-breakpoint-three) {
  .intro {
    &__photo-name-section {
      &__vertical-divider {
        &-appear-done {
          height: $vertical-divider-height-end-bp-three;
        }
      }
    }
  }
}
