@import url(https://fonts.googleapis.com/css?family=Ubuntu&display=swap);
.connect {
  display: flex;
  flex-direction: row; }
  .connect a {
    margin-right: 30px;
    width: 55px;
    height: 55px; }
    .connect a svg {
      width: 55px;
      height: 55px;
      fill: #102542; }
    @media only screen and (max-width: 660px) {
      .connect a {
        width: 50px;
        height: 50px; }
        .connect a svg {
          width: 50px;
          height: 50px; } }
    @media only screen and (max-width: 450px) {
      .connect a {
        width: 45px;
        height: 45px; }
        .connect a svg {
          width: 45px;
          height: 45px; } }
    @media only screen and (max-width: 360px) {
      .connect a {
        width: 40px;
        height: 40px; }
        .connect a svg {
          width: 40px;
          height: 40px; } }
  .connect a:last-child {
    margin-right: 0; }
  .connect__icons-appear {
    opacity: 0; }
  .connect__icons-appear-done {
    opacity: 1;
    transition: opacity 0.4s ease-in-out;
    -moz-transition: opacity 0.4s ease-in-out;
    -webkit-transition: opacity 0.4s ease-in-out;
    -o-transition: opacity 0.4s ease-in-out; }

.name {
  font-family: "Ubuntu", sans-serif;
  font-weight: bold;
  overflow-x: hidden; }
  .name .sliding-names-appear {
    margin-left: -100%; }
  .name .sliding-names-appear-done {
    margin-left: 0%;
    -webkit-transition-delay: 100ms;
            transition-delay: 100ms;
    -webkit-transition-property: margin-left;
    transition-property: margin-left;
    -webkit-transition-duration: 400ms;
            transition-duration: 400ms; }
  .name .first-name {
    font-size: 90px;
    color: #102542; }
  .name .last-name {
    font-size: 60px;
    color: #b3a394; }

@media only screen and (max-width: 660px) {
  .name .first-name {
    font-size: 65px; }
  .name .last-name {
    font-size: 40px; } }

@media only screen and (max-width: 450px) {
  .name .first-name {
    font-size: 50px; }
  .name .last-name {
    font-size: 30px; } }

@media only screen and (max-width: 360px) {
  .name .first-name {
    font-size: 35px; }
  .name .last-name {
    font-size: 20px; } }

.spinner {
  display: flex;
  align-items: center;
  justify-content: center; }
  .spinner-appear {
    background: #f87060;
    width: 170px;
    height: 170px;
    border-radius: 50%;
    border: 0px solid #fff;
    border-color: #f87060;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
    .spinner-appear .closeup {
      height: 170px;
      width: 170px;
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
      border-radius: 50%; }
  .spinner-appear-active {
    background: #f87060;
    width: 170px;
    height: 170px;
    border-radius: 10%;
    border: 20px solid #fff;
    border-color: #f87060;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    -webkit-transition-property: border, border-radius, width, height, -webkit-transform;
    transition-property: border, border-radius, width, height, -webkit-transform;
    transition-property: transform, border, border-radius, width, height;
    transition-property: transform, border, border-radius, width, height, -webkit-transform;
    -webkit-transition-duration: 0.85s;
            transition-duration: 0.85s; }
    .spinner-appear-active .closeup {
      height: 170px;
      width: 170px;
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
      border-radius: 50%;
      -webkit-transition-property: height, width;
      transition-property: height, width;
      -webkit-transition-duration: 0.85s;
              transition-duration: 0.85s; }
  .spinner-appear-done {
    background: #f87060;
    width: 170px;
    height: 170px;
    border-radius: 10%;
    border: 20px solid #fff;
    border-color: #f87060;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    transform: rotate(180deg); }
    .spinner-appear-done .closeup {
      height: 170px;
      width: 170px;
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
      border-radius: 50%; }

@media only screen and (max-width: 660px) {
  .spinner-appear {
    width: 100px;
    height: 100px; }
    .spinner-appear .closeup {
      height: 100px;
      width: 100px; }
  .spinner-appear-active, .spinner-appear-done {
    width: 100px;
    height: 100px; }
    .spinner-appear-active .closeup, .spinner-appear-done .closeup {
      height: 100px;
      width: 100px; } }

@media only screen and (max-width: 450px) {
  .spinner-appear {
    width: 80px;
    height: 80px; }
    .spinner-appear .closeup {
      height: 80px;
      width: 80px; }
  .spinner-appear-active, .spinner-appear-done {
    width: 80px;
    height: 80px; }
    .spinner-appear-active .closeup, .spinner-appear-done .closeup {
      height: 80px;
      width: 80px; } }

@media only screen and (max-width: 360px) {
  .spinner-appear {
    width: 60px;
    height: 60px; }
    .spinner-appear .closeup {
      height: 60px;
      width: 60px; }
  .spinner-appear-active, .spinner-appear-done {
    width: 60px;
    height: 60px; }
    .spinner-appear-active .closeup, .spinner-appear-done .closeup {
      height: 60px;
      width: 60px; } }

.intro {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .intro__particles {
    position: absolute;
    z-index: -1;
    background-color: #ffffff;
    left: 0;
    right: 0;
    bottom: 0%;
    top: 0; }
  .intro__photo-name-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 30px; }
    .intro__photo-name-section__vertical-divider-appear {
      height: 0px;
      width: 2px;
      margin-left: 20px;
      margin-right: 20px;
      background: #102542; }
    .intro__photo-name-section__vertical-divider-appear-done {
      height: 200px;
      width: 2px;
      margin-left: 20px;
      margin-right: 20px;
      background: #102542;
      -webkit-transition-property: height;
      transition-property: height;
      -webkit-transition-delay: 300ms;
              transition-delay: 300ms;
      -webkit-transition-duration: 300ms;
              transition-duration: 300ms; }

@media only screen and (max-width: 660px) {
  .intro__photo-name-section__vertical-divider-appear-done {
    height: 125px; } }

@media only screen and (max-width: 450px) {
  .intro__photo-name-section__vertical-divider-appear-done {
    height: 105px; } }

@media only screen and (max-width: 360px) {
  .intro__photo-name-section__vertical-divider-appear-done {
    height: 80px; } }

.personal-website {
  position: absolute;
  width: 100vw;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0; }

